import type { JSONSchemaType } from 'ajv';
import type { IComponentButtonField } from '~/typings/types/component.types';

export const strictButtonSchema: JSONSchemaType<IComponentButtonField> = {
  type: 'object',
  properties: {
    title: { type: 'string' },
    value: { type: 'string' },
    type: { type: 'string' },
  },
  required: ['title', 'value', 'type'],
  additionalProperties: false,
};

export const nullableButtonSchema: JSONSchemaType<IComponentButtonField> = {
  type: 'object',
  nullable: true,
  properties: {
    title: { type: 'string' },
    value: { type: 'string' },
    type: { type: 'string' },
  },
  required: ['title', 'value', 'type'],
  additionalProperties: false,
};
